import { modesType } from '../../../modules/note/pc/consts';
import { MarkdownRender } from '../../../modules/note/pc/MarkdownRender';
import { useNoteContext } from '../../../modules/note/pc/NoteContext';
import { Button } from 'antd';
import { isNil } from 'lodash-es';
import { useSearchParams } from 'react-router-dom';

const Detail = () => {
	const {
		noteDetail: { data },
		id,
	} = useNoteContext();
	const [searchParams, setSearchParams] = useSearchParams();

	if (data) {
		return (
			<>
				<MarkdownRender
					key={data.noteId?.toString() ?? '-1'}
					detail={data}
					lightWord={['async', 'sd']}
				/>

				{!isNil(id) && (
					<Button
						block
						color="primary"
						size="large"
						onClick={() => {
							// router.push({
							//   pathname: mobileNoteUrl,
							//   query: {
							//     action: modesType.editAndPreview,
							//     id: id,
							//   },
							// });

							setSearchParams({
								action: modesType.editAndPreview,
								uuid: id,
							});
						}}
					>
						编辑
					</Button>
				)}
			</>
		);
	}

	return null;
};

export default Detail;
