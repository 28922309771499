import MobileNoteModule from '../../../modules/note/mobile/MobileNoteModule';
import { NoteContextComponent } from '../../../modules/note/pc/NoteContext';

export function MobileNoteIndex() {
	return (
		<NoteContextComponent>
			<MobileNoteModule />
		</NoteContextComponent>
	);
}
