import { modesType, tabs } from '../../../modules/note/pc/consts';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useNoteContext } from '../pc/NoteContext';
import Detail from './Detail';
import List from './List';
import { Menu } from 'antd';

const MobileNoteModule = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { id: uuid, listHook, action } = useNoteContext();
	const [tab, setTab] = useState<string>(tabs[0].title);

	return (
		<>
			<Menu
				mode="horizontal"
				items={tabs.slice(0, 2).map((item) => {
					return {
						key: item.title,
						label: item.title,
						onClick: ({ key }) => {
							console.log(key);
							const selectedTab = tabs.find((i) => i.title === key);

							setSearchParams({
								action: selectedTab?.action ?? modesType.create,
								uuid: selectedTab?.action === modesType.create ? '' : uuid,
							});

							setTab(key);
						},
					};
				})}
			/>

			<div className="flex flex-col">
				{tab === tabs[1].title && (
					<List
						onItemClick={(item) => {
							setTab(tabs[2].title);

							setSearchParams({
								action: searchParams.get('action') as string,
								uuid: item.uuid,
							});
						}}
					/>
				)}
				{tab === tabs[2].title && <Detail />}
			</div>
		</>
	);
};

export default MobileNoteModule;
