import { MobileNoteIndex } from '../../modules/note/mobile/MobileNoteIndex';

const MobileNotePage = () => {
	//   useEffect(() => {
	//     if (!isMobile) {
	//       router.push(`/pc/search`);
	//     }
	//   }, [isMobile, router]);

	return MobileNoteIndex();
};

export default MobileNotePage;
